import React, { useState, useEffect } from 'react';
import {
  AppBarContainer,
  AppBarDiv,
  MobileMenuIcon,
  AppBarEnd
} from './AppBarElements';
import roboa_alqalaa_logo from '../../assets/roboa_alqalaa_logo.png';
import { useSelector } from 'react-redux';
import UserMenu from '../../components/UserMenu';
import LanguageSelector from '../../components/LanguageSelector';
import { HiMenu } from 'react-icons/hi';

function AdminAppBar({ setMobileBarOpen }) {
  
  const [scrollPosition, setScrollPosition] = useState(0);

  const isRTL = useSelector(state => state.isRTL);

  const handleScrollPosition = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScrollPosition, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScrollPosition);
    };
  }, []);

  return (
    <>
      <AppBarContainer isTransparent={scrollPosition < 10}>
        <AppBarDiv>
          <MobileMenuIcon isRTL={isRTL} onClick={() => setMobileBarOpen(true)}>
            <HiMenu />
          </MobileMenuIcon>
          <AppBarEnd isRTL={isRTL}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{margin: '0px 5px'}}>
                <LanguageSelector />
              </div>
              <UserMenu />
            </div>
          </AppBarEnd>
        </AppBarDiv>
      </AppBarContainer>
    </>
  )
}

export default AdminAppBar;
